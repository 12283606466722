import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/k39/video.mp4";
import Feature from "../../img/client_pages/k39/feature.png";
import Design from "../../img/client_pages/k39/design.png";
import Iphone from "../../img/client_pages/k39/iphone.png";

export default class K39 extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.K39MainImg}
        video={Video}
        title="Kompany 39"
        theBrand={
          "Kompany39 is a hockey-focused production company that partners with big names (like NHL player Joe Pavelski) to create digital content for aspiring hockey players."
        }
        ourWork={[
          "Brand Identity",
          "Web Design",
          "Social Style",
          "Social Strategy",
          "Email Marketing"
        ]}
        brandDesign={
          "In order to present a polished, professional package that remained accessible to hockey players and fans alike, we implemented simple guidelines that paired aspirational imagery with a casual voice to build a bridge between larger-than-life hockey stars and those who admire them."
        }
        featureImg={Feature}
        webDesignText={
          "The company’s internal blog, the Vault, features posts from current and former NHL players who give their best tips for success. We knew Kompany39’s website had to be epic in order to match the level of athletes with which they partner, so we designed a sleek format that allowed for each player’s post to be personalized."
        }
        webDesignImg={Design}
        socialDesignText={
          "Kompany39’s social media accounts are a collection of motivational content, pro tips, and engaging conversations. In order to grow the company’s following and build a supportive and engaged community, we are actively creating educational and inspirational content and encouraging conversations on every post."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
