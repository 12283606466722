import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import K39 from "../components/ClientPages/K39";

export default class Kompany39Page extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Kompany39" />
        <K39 />
      </Fragment>
    );
  }
}
